@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Krona+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

body {
  font-family: "Open Sans", sans-serif;
}

.dataResults::-webkit-scrollbar {
  display: none;
}

.MainFont {
  font-family: "Krona One", sans-serif;
}

.root,
#root,
#docs-root {
  --back: #f5f5f5;
  --secback: #ffffff;

  --primary: #0e9e2c;
  --secondary: #1bc23e;

  --textcolor: #1a1a1a;
}

.cls-1 {
  fill: var(--secback);
}
.cls-2 {
  fill: var(--secback);
}
